.app {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url('file:/var/www/html/dlom121223/dlom_frontend_code_base/calculator.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-app {
  padding: 20px;
  background-color: #ffffffdb;
  width: 460px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  margin-top: 30px;
}

.auth-form-container,
.login-form,
.signup-form {
  display: flex;
  flex-direction: column;
}

.login-btn {
  padding: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-weight: 550;
}

.login-username-input {
  text-align: justify;
}

.username-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.input {
  margin: 0.5rem 0;
  padding: 1rem;
  border-color: aliceblue;
  margin-left: 13px;
  width: 374px;
  border: none;
  border-bottom: 1px ridge black;
  outline: none;
  background: transparent !important;
}

.login-button {
  border-radius: 10px;
  margin-top: 20px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 550;
  width: 100%;
}

.link-btn {
  background: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 550;
  margin-top: 20px;
}

.signUp-button {
  cursor: pointer;
  text-decoration: underline;
}

.forget-password-button {
  cursor: pointer;
  text-decoration: underline;
}

.forget-btn {
  background: none;
  padding-bottom: 23px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 550;
  margin-top: 20px;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

.popup {
  position: fixed;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 393px;
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.popup-content {
  text-align: center;
}

.popup-content p {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.popup-refused {
  position: fixed;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 435px;
  padding: 20px;
  background: #ea0707;
  border: 1px solid #f00c0c;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.popup-content-refused {
  text-align: center;
}

.popup-content-refused p {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.new-password-app {
  padding: 20px;
  background-color: #ffffffdb;
  width: 450px;
  border-radius: 15px;
  text-align: center;
  display: flex;
}

.signUp-app {
  padding: 20px;
  background-color: #ffffffdb;
  width: 476px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  margin-top: 23px;
}

.route-dashboard {
  background-color: white;
}

Label {
  text-align: left;
  padding: 0.25rem 0;
  display: inline-block !important;
  font-weight: 550 !important;
  text-transform: capitalize !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;

}

.signUp-input {
  margin: 0.5rem 0;
  padding: 1rem;
  border-color: aliceblue;
  width: 390px;
  margin-left: 13px;
  border: none;
  border-bottom: 1px ridge black;
  outline: none;
  background: transparent !important;
}

.forget-input {
  margin: 0.5rem 0;
  padding: 1rem;
  border-color: aliceblue;
  width: 380px;
  margin-left: 13px;
  border: none;
  border-bottom: 1px ridge black;
  outline: none;
  background: transparent !important;
}

.new-password-input {
  margin: 0.5rem 0;
  padding: 1rem;
  border-color: aliceblue;
  width: 372px;
  margin-left: 13px;
  border: none;
  border-bottom: 1px ridge black;
  outline: none;
  background: transparent !important;
}

button {
  border: none;
  background-color: dodgerblue;
  padding: 17px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.link-btn {
  background: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 550;
  margin-top: 20px;
}

.new-password-btn {
  background: none;
  text-decoration: underline;
  padding-top: 3px;
  padding-bottom: 23px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 550 !important;
}

.signUp-btn {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 550 !important;
  font-size: 40px;
}

.forgetPassword-btn {
  font-size: 40px;
  padding: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 550 !important;
  display: flex;
  justify-content: center;
}

#new-password {
  margin-bottom: 14px;
}

.dashboard {
  max-width: 900px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 50px;

}

.calculator {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 2.75rem;
  line-height: 2.2;
  letter-spacing: -0.00833em;
  text-align: center !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
}

.year-month {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
  text-align: center !important;
  text-decoration: underline;
}

.profile-icon {
  position: relative;
  margin-right: 30px;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
}

.profile-options {
  color: rgb(255 255 255 / 64%);
  font-size: 17px;
  size: ledger;
}

.logout-icon {
  margin-right: 8px;
  margin-bottom: 2px;
}

.user-logout {
  cursor: pointer;
}

.user-logout:hover {
  color: white;
}

.profile-icon .dropdown a:hover {
  color: #212529;
}

.user-profile-name {
  font-size: 2.75rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin-top: 10px;
  text-align: center !important;
  text-transform: uppercase;
  font-weight: 600;
}

.ticker-profile-name {
  font-size: 2.75rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin-top: 10px;
  text-align: center !important;
  text-transform: uppercase;
  font-weight: 600;
}

.table-border-box {
  margin-top: 37px;
  margin-left: 100px
}

.left-navbar ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0rem
}

.left-navbar li {
  margin-bottom: 10px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.navbar-text-color {
  color: rgb(255 255 255 / 64%);
  margin-left: 36px;
  margin-top: 34px;
  transition: color 0.3s;
}

.menu-text-color {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 16px;
  margin-top: 13px;
  transition: color 0.3s;
  width: 40px;
  height: 39px;
  border-radius: 50%;
  border: 2px solid white;
  display: inline-block;
  border-width: 4px;
  animation: blink 1s infinite;
  background: linear-gradient(45deg, #929292, #5e4b4b);
}

@keyframes blink {

  0%,
  100% {
    color: rgba(255, 255, 255, 0.5);
  }

  50% {
    color: white;
  }
}

.navbar-text-span:hover,
.menu-text-color:hover {
  color: white;
  cursor: pointer;
}

.left-navbar .icon {
  margin-right: 8px;
  margin-bottom: 4px;
}

.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.left-navbar {
  background-color: #343a41 !important;
  padding: 10px;
  height: 100%;
}

.top-navbar {
  width: 100%;
  background-color: #343a41 !important;
  min-height: 80px !important;
  z-index: 1;
}

.dashboard-menu-options {
  display: none;
}

.dashboard-menu-options {
  display: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.content {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

#pagination-entries {
  line-height: 1;
  margin-left: 6px;
  margin-right: 6px;
}

.table-icon {
  margin-right: 6px;
  margin-bottom: 2px;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-wave {
  position: relative;
  width: 100px;
  height: 50px;
}

.loading-wave div {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #b1c4d1;
  border-radius: 50%;
  animation: dot 1s linear infinite;
}

.loading-wave div:nth-child(1) {
  left: 0;
  animation-delay: 0s;
}

.loading-wave div:nth-child(2) {
  left: 30px;
  animation-delay: 0.2s;
}

.loading-wave div:nth-child(3) {
  left: 60px;
  animation-delay: 0.4s;
}

.loading-wave div:nth-child(4) {
  left: 90px;
  animation-delay: 0.6s;
}

@keyframes dot {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }

  150% {
    transform: scale(1.5);
  }
}

.calculation-export-csv {
  text-align: end;
}

.dashboard-select-option {
  margin-top: 13px;
  margin-left: 15px;
}

.export-csv {
  display: flex;
  justify-content: end;
  padding-left: 10px;
}

.card-header {
  background: #18171778 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 20px !important;
}

.card-body {
  border: ridge;
}

.calculator-submit {
  padding: 7px 35px !important;
  background-color: #343a41 !important;
}

.calculator-blockage {
  padding: 7px 35px !important;
  background-color: #343a41 !important;
  margin-right: 48px;
}

.calculator-icon {
  margin-left: 6px;
  margin-top: 5px;
  font-size: 22px;
  transform: rotate(322deg);
}

.card-header-result-table {
  background: #18171778 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 20px !important;
  height: 55px !important;
}

.calculation-result-search {
  border-radius: 8px !important;
  border-color: white !important;
  outline: none;
}

.calculation-result-table {
  font-size: large;
}

.tickers-result-export-csv {
  padding: 6px 25px !important;
  background-color: #343a41 !important;
}

.search-export-csv {
  display: flex;

}

.dlom-calculator {
  color: white;
  margin-left: 8px;
  margin-top: 13px;
  font-size: 25px;
  font-weight: 700;
}

.calculation-output-csv {
  display: flex;
  justify-content: space-between;
}

.calculation-result-search::placeholder {
  padding-left: 5%;
  font-size: large;
}

.title-column {
  width: 450px;
}

.calculation-records {
  width: 200px;
}

.calculation-volatility-records {
  width: 228px;
}

.signUp-input-details {
  text-align: justify;
  margin-top: 10px;
}

.login-password-input {
  text-align: justify;
  margin-top: 30px;
}

.liquidation-period {
  width: 225px;
  display: flex;
  border-bottom-width: inherit !important;
}

.password-error-message {
  margin-top: 10px;
  color: #ff0000 !important;
  text-align: justify !important;
}

.dlom-calculator-dropdown {
  word-wrap: normal;
  margin-top: 13px !important;
  margin-left: 10px !important;
  background-color: #343a41 !important;
  border: none;
  font-size: 28px !important;
  color: white;
  font-weight: 700;
  outline: none;
  margin-top: 58px;
}

.calculation-options {
  font-weight: 700;
  font-size: 20px;
} 

.dlom-calculator-type {
  display: none;
}

.dlom-alert-message {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.dlom-alert-observation {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: red;
}

.dlom-calculator-type-icon {
  display: none;
}

.blockage-toggle {
  color: rgb(255 255 255 / 64%);
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
}

.blockage-toggle-options {
  display: flex;
  justify-content: space-between;
}

.top-navbar-blockage {
  margin-left: 39px;
  display: flex;
  margin-top: -8px;
}

.react-datepicker__month .react-datepicker__month-text, 
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
  padding: 8px;
}

.dlom-toggle {
  margin-left: 39px;
  color: rgb(255 255 255 / 64%);
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
}

.tickers-date {
  font-size: 14px;
}

.liquidation-annualized-value {
  width: 30%;
}

.apiKey-cardbody {
  height: 10rem;
}

.apiKey-table {
  margin-top: 24px;
}

.apiKey-input-field {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
}

.apiKey-button {
  font-size: 25px;
  cursor: pointer;
}

.apiKey-blocked-button {
  font-size: 25px;
}

@media (max-width: 767px) {
  .login-app,
  .signUp-app,
  .new-password-app {
    padding: 25px;
    background-color: #ffffffdb;
    width: 85%;
    border-radius: 15px;
    text-align: center;
    display: flex;
    margin-bottom: 20px
  }

  .auth-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .login-parent-inputs {
    width: 100%;
  }

  .login-username-input,
  .login-password-input,
  .signUp-input-details,
  .forget-input {
    display: flex;
    align-items: center;
  }

  .input,
  .signUp-input,
  .forget-input,
  .new-password-input {
    width: -webkit-fill-available;
  }

  button {
    border: none;
    background-color: dodgerblue;
    padding: 10px;
  }

  .login-button {
    border-radius: 5px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    width: 50%;
    margin-top: 27px;
  }

  .forgetPassword-btn {
    font-size: 26px;
  }

  .popup,
  .popup-refused {
    width: 320px;
  }

  .left-navbar {
    display: none;
  }

  .dashboard-menu-options {
    display: block;
  }

  .dashboard {
    margin-left: 0%;
  }

  .calculator {
    font-size: 26px;
  }

  .dashboard-menu-options {
    color: rgb(255 255 255 / 64%);
    margin-left: 16px;
    font-size: 24px;
  }

  .profile-icon {
    margin-top: 28px;
    text-align: unset !important;
    display: flex;
    justify-content: space-between;
  }

  .user-profile-name,
  .ticker-profile-name {
    font-size: 1.75rem;
  }

  .calculation-title-size {
    font-size: 13px !important;
    margin-top: 10px;
  }  

  .card-header {
    font-size: 13px !important;
  }

  .calculator-submit {
    padding: 4px 10px !important;
    background-color: #343a41 !important;
  }

  .calculator-blockage {
    padding: 4px 10px !important;
    background-color: #343a41 !important;
  }

  .calculation-result-table,
  .calculation-records,
  .title-column {
    font-size: 11px;
  }

  .liquidation-result-table {
    font-size: 8px;
  }

  .liquidation-annualized-value {
    font-size: 8px;
  }

  .liquidation-records{
    font-size: 9px;
  }

  Label {
    font-size: 12px;
  }

  .text-danger {
    font-size: 13px;
  }

  .dropdown {
    top: 100%;
    left: 0;
    background-color: #343a41 !important;
    border: 1px solid #343a41 !important;
    padding: 8px;
    z-index: 1000;
  }

  .dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: rgb(255 255 255 / 64%);
    margin-left: 25px;
  }

  .dropdown li {
    padding: 8px;
    cursor: pointer;
  }

  .dropdown li:hover {
    background-color: #f1f1f1;
  }

  .ticker-records th,
  .ticker-records td {
    font-size: 9px;
  }

  .d-flex {
    display: flex !important;
    font-size: 8px;
  }

  #pagination-entries {
    font-size: 6px;
  }

  .calculation-result-search {
    font-size: 10px;
    margin-top: 8px;
    border-radius: 6px !important;
    width: 84px;
    outline: none;
  }

  .calculation-result-search::placeholder {
    padding-left: 5%;
    font-size: 7px;
  }

  .tickers-result-export-csv {
    padding: 4px 11px !important;
    background-color: #343a41 !important;
  }

  .pagination {
    --bs-pagination-font-size: 5px !important;
  }

  .table>:not(caption)>*>* {
    padding: 3px !important;
  }

  .liquidation-period {
    width: 0%;
  }

  .dlom-calculator-type {
    display: block;
    margin-top: 0px  !important;
    font-size: 17px !important;
    word-wrap: normal;
    margin-left: 2px !important;
    background-color: #343a41 !important;
    border: none;
    color: white;
    font-weight: 700;
    outline: none;
    margin-top: 58px;
  }

  .dlom-calculator-type-icon {
    display: block;
    display: flex;
  }

  .calculation-options {
    font-size: 10px !important;
  }

  .calculator-icon {
    margin-top: 0px;
    font-size: 16px;
    transform: rotate(322deg);
    margin-right: 4px;
    margin-bottom: 6px;
  } 

 .menu-text-color {
    color: rgba(255, 255, 255, 0.5);
    margin-left: 17px;
    margin-top: 0px;
    transition: color 0.3s;
    width: 33px;
    height: 31px;
    border-radius: 50%;
    border: 2px solid white;
    display: inline-block;
    border-width: 4px;
    animation: blink 1s infinite;
    background: linear-gradient(45deg, #929292, #5e4b4b);
  }

  .dlom-alert-message {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
  }

  .top-navbar-blockage {
    margin-left: 0px;
    display: flex;
    margin-top: -10px;
  }

  .dlom-toggle {
    margin-left: 0px;
  }

  .profile-options { 
    font-size: 16px;
  }

  .popup-content-refused p {
    font-size: 11px;
    margin: 0;
    font-weight: 600;
  }

  .dlom-alert-observation { 
    font-size: 12px;

  }

  .blockage-toggle-options {
    margin-left: -9px;
  }

  .calculation-volatility-records {
    font-size: 11px;
  }

  .apiKey-input-field {
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    font-size: 8px !important;
    margin-right: 72px;

  }

  .apiKey-button {
    height: 25px;
    font-size: 15px !important;
    --bs-btn-padding-x: none !important;
    --bs-btn-padding-y: none !important;
  }

  .apiKey-blocked-button {
    height: 25px;
    font-size: 15px !important;
    --bs-btn-padding-x: none !important;
    --bs-btn-padding-y: none !important;
  }

  .apiKey-header {
    font-size: 11px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard {
    margin-left: 20px !important;
  }

  .d-flex {
    display: flex !important;
    font-size: 14px;
  }

  .ticker-records th,
  .ticker-records td {
    font-size: 12px;
  }

  .calculation-result-search {
    margin-top: 7px;
    outline: none;
  }

  .liquidation-period {
    width: 0%;
    display: flex;
  }
}
